"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickType2 = exports.PickGroupType = void 0;
var PickGroupType;
(function (PickGroupType) {
    PickGroupType[PickGroupType["BettorHistorical"] = 0] = "BettorHistorical";
    PickGroupType[PickGroupType["BettorRecent"] = 1] = "BettorRecent";
    PickGroupType[PickGroupType["BettorActive"] = 2] = "BettorActive";
    PickGroupType[PickGroupType["BettorCounts"] = 3] = "BettorCounts";
    PickGroupType[PickGroupType["BettorForSale"] = 4] = "BettorForSale";
    PickGroupType[PickGroupType["BettorActiveCount"] = 5] = "BettorActiveCount";
    PickGroupType[PickGroupType["Featured"] = 6] = "Featured";
    PickGroupType[PickGroupType["Game"] = 7] = "Game";
    PickGroupType[PickGroupType["GameActive"] = 8] = "GameActive";
    PickGroupType[PickGroupType["GameActiveCount"] = 9] = "GameActiveCount";
    PickGroupType[PickGroupType["GameCounts"] = 10] = "GameCounts";
    PickGroupType[PickGroupType["Team"] = 11] = "Team";
})(PickGroupType || (exports.PickGroupType = PickGroupType = {}));
var PickType2;
(function (PickType2) {
    PickType2[PickType2["AwayMoneyline"] = 0] = "AwayMoneyline";
    PickType2[PickType2["HomeMoneyline"] = 1] = "HomeMoneyline";
    PickType2[PickType2["AwaySpread"] = 2] = "AwaySpread";
    PickType2[PickType2["HomeSpread"] = 3] = "HomeSpread";
    PickType2[PickType2["Over"] = 4] = "Over";
    PickType2[PickType2["Under"] = 5] = "Under";
})(PickType2 || (exports.PickType2 = PickType2 = {}));
