"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiResponse = void 0;
__exportStar(require("./bettor"), exports);
__exportStar(require("./game"), exports);
__exportStar(require("./injuries"), exports);
__exportStar(require("./odds"), exports);
__exportStar(require("./sport"), exports);
__exportStar(require("./stats"), exports);
__exportStar(require("./standings"), exports);
__exportStar(require("./team"), exports);
__exportStar(require("./flags"), exports);
__exportStar(require("./pagination"), exports);
__exportStar(require("./validation"), exports);
__exportStar(require("./picks"), exports);
__exportStar(require("./achievements"), exports);
__exportStar(require("./marketplace"), exports);
function apiResponse(data, status = "success", message, code = 200) {
    return {
        status,
        data,
        message,
        code,
    };
}
exports.apiResponse = apiResponse;
